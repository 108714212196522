<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="pt-2 px-2">
          รายงานการรับโบนัส
        </div>
      </b-card-title>
      <b-card-body>
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr v-model="dateStart" class="form-control" placeholder="เลือกวันที่" />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr v-model="dateEnd" class="form-control" placeholder="เลือกวันที่" />
          </div>
          <div class="d-flex align-items-start">
            <div class="btn-disable">
              คำค้นหา
            </div>
            <input id="username" v-model="search_val" type="text" class="form-control" style="width: 230"
              placeholder="กรอกข้อมูล">
          </div>
          <button class="btn btn-primary ml-1" @click="getData()">
            ค้นหา
          </button>
          <button v-b-modal.exportDepositModal class="btn btn-warning ml-1">
            Export
          </button>

          <b-modal id="exportDepositModal" title="ระบุรหัสผ่าน" @ok="checkExportPassword">
            <b-form-input v-model="exportPassword" />
          </b-modal>
        </div>

      </b-card-body>

      <div class="row px-2">
        <div class="col-12 col-md-6">
          <b-alert show variant="primary" class="text-center">
            <div>
              <h3 class="alert-heading">
                ยอดรวมตามวันที่
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ Number(summary).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
            </div>
          </b-alert>
        </div>
        <div class="col-12 col-md-6">
          <b-alert show variant="primary" class="text-center">
            <div>
              <h3 class="alert-heading">
                ยอดรวม
                <div>ทั้งหมด</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ Number(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
            </div>
          </b-alert>
        </div>
      </div>

      <b-table striped hover small responsive show-empty class="mt-2 position-relative items-center" :per-page="perPage"
        :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">

        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
              'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-button title="รายละเอียด" variant="gradient-info" size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username } })">
              <i class="fas fa-address-card" />
            </b-button>
          </div>
        </template>
        <template #cell(bank)="{ item }">
          <img :src="`/bankIcon/${item.user_bankimg}`" alt="" height="25" width="25" class="rounded-circle">
        </template>

        <template #cell(amount)="{ item }">
          <span class="text-success">
            {{ parseFloat(item.amount).toFixed(2) }}
          </span>
        </template>
        <template #cell(to_acc)="{ item }">
          <img :src="`/bankIcon/${item.to_accimg}`" alt="" height="25" width="25" class="rounded-circle">
          <span>
            {{ item.to_accname }} {{ item.to_accno }}
          </span>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getData()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="page" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="getData()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BModal, BFormInput, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BAlert, BModal,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardBody,
    BOverlay,
    BIconController,
    BButton,
    flatPickr,
  },
  data: () => ({
    search_val: '',
    perPage: 50,
    pageOptions: [50, 100, 150, 200],
    totalRows: 1,
    page: 1,
    exportPassword: '',
    fields: [
      { key: 'detail', label: 'รายละเอียด' },
      { key: 'username', label: 'ยูสเซอร์' },
      // { key: 'user_tel', label: 'เบอร์โทรศัพท์' },
      { key: 'type', label: 'ประเภท', sortable: true },
      { key: 'coupon_type', label: 'ประเภทคูปอง', sortable: true },
      { key: 'coupon_code', label: 'รหัสคูปอง', sortable: true },
      { key: 'amount', label: 'รางวัล', sortable: true },
      { key: 'created_at', label: 'วันที่รับ', formatter: value => moment(value).format('DD/MM/YYYY HH:mm') },
    ],
    items: [],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(20, 'days').format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    summary: 0,
    totalAmount: 0
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/bonus', {
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            keywords: this.search_val,
          },
        })
        this.totalRows = (this.perPage === 'all') ? data.totalPages : data.total
        this.items = data.data;
        this.summary = data.summary;
        this.totalAmount = data.totalAmount;
        // console.log(data)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    // async checkExportPassword() {
    //   if (this.exportPassword === 'gSEnn9hEH6Ts5wH') {
    //     this.exportData()
    //   } else {
    //     this.$swal({
    //       icon: 'error',
    //       title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
    //       text: 'รหัสผ่านไม่ถูกต้อง',
    //       customClass: {
    //         confirmButton: 'btn btn-primary',
    //       },
    //     })
    //   }
    // },
    async exportData() {
      try {
        const { data } = await this.$http.get('/export/deposits', {
          responseType: 'blob',
          params: {
            page: this.page, perPage: this.perPage, dateStart: this.dateStart, dateEnd: this.dateEnd,
          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )
        // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
        link.setAttribute('download', 'ReportDepsits.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {

      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
